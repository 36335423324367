$(document).ready(() => {

    // Define the countUp function using jQuery
    function CountUp(el) {
        this.el = $(el);
        this.number = this.el.find("[data-countup-number]");
        this.observerOptions = { root: null, rootMargin: "0px 0px", threshold: 0 };
        this.observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    // Convert data attribute value to string before using replace
                    const end = parseFloat(
                        String($(entry.target).data("countup-number")).replace(/,/g, "")
                    );
                    const decimals = this.countDecimals(end);
                    if (entry.isIntersecting) {
                        this.iterateValue(entry.target, end, decimals);
                    }
                });
            },
            this.observerOptions
        );

        // Initialize the countUp instance
        this.init = function () {
            if (this.number.length > 0) {
                this.number.each((index, el) => {
                    this.observer.observe(el);
                });
            }
        };

        // Function to animate the counting up
        this.iterateValue = function (el, end, decimals) {
            const start = 0;
            const duration = 2500;
            let startTimestamp = null;

            const step = (timestamp) => {
                if (!startTimestamp) startTimestamp = timestamp;
                const elapsedPercent = (timestamp - startTimestamp) / duration;
                const easedProgress = Math.min(this.easeOutQuint(elapsedPercent), 1);
                let interimNumber = Math.abs(easedProgress * (end - start) + start);
                $(el).html(this.formatNumber(interimNumber, decimals));
                if (easedProgress < 1) {
                    window.requestAnimationFrame(step);
                }
            };

            // Start animation
            window.requestAnimationFrame(step);
        };

        // Easing functions
        this.easeOutQuad = function (x) {
            return 1 - Math.pow(1 - x, 3);
        };

        this.easeOutQuint = function (x) {
            return 1 - Math.pow(1 - x, 5);
        };

        // Function to count decimals
        this.countDecimals = function (val) {
            if (Math.floor(val) === val) return 0;
            return val.toString().split(".")[1].length || 0;
        };

        // Function to format the number with commas
        this.formatNumber = function (val, decimals) {
            return val.toLocaleString("en-US", {
                minimumFractionDigits: decimals,
                maximumFractionDigits: decimals
            });
        };

        // Call init method to start observing elements
        this.init();
    }

    // Iterate over elements with data-module="countup" using jQuery
    $('[data-module="countup"]').each(function () {
        new CountUp(this);
    });


    /*==============================================================================*/
    $('.client-slider').slick({
        draggable: true,
        slidesToShow: 5,
        infinite: true,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        speed: 1000,
        centerMode: true,
        centerPadding: "150px",
        variableWidth: false,
        initialSlide: 1,
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    centerMode: true,
                    centerPadding: "60px",
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            } ,
            {
                breakpoint: 1024,
                settings: {
                    centerMode: true,
                    centerPadding: "30px",
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    centerMode: true,
                    centerPadding: "60px",
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 360,
                settings: {
                    slidesToShow: 2,
                    centerMode: true,
                    slidesToScroll: 1
                }
            }
        ],
    });

    /************************************************************************/

    $('.team-panel').slick({
        draggable: false,
        slidesToShow: 5,
        infinite: false,
        //slidesToScroll: 1,
        autoplay: false,
        centerMode: true,
        autoplaySpeed: 2000,
        arrows: false,
        speed: 1000,
        variableWidth: false,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 10000,
                settings: "unslick"
            },
            {
                breakpoint: 1250,
                settings: {
                    draggable: true,
                    centerPadding: "30px",
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    centerPadding: "0px",
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    draggable: true,
                    autoplay: true,
                    dots: true,
                }
            },
            {
                breakpoint:500,
                settings: {
                    centerPadding: "60px",
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    draggable: true,
                    autoplay: true,
                    dots: true,
                }
            },
            {
                breakpoint:400,
                settings: {
                    centerPadding: "30px",
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    draggable: true,
                    autoplay: true,
                    dots: true,
                }
            },
        ],
    });

    /************************************************************************/

    $('.blogs-panel').slick({
        draggable: true,
        slidesToShow: 4,
        infinite: false,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        speed: 1000,
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ],
    });

    /************************************************************************/
    
    $('.collapse').on('shown.bs.collapse', function(e) {
        var $card = $(this).closest('.accordion-item');
        var $open = $($(this).data('parent')).find('.collapse.show');
        
        /* var additionalOffset = 0;
        if($card.prevAll().filter($open.closest('.accordion-item')).length !== 0)
        {
              additionalOffset =  $open.height() - 300;
        } */
        $('html,body').animate({
          scrollTop: $card.offset().top - 60
        }, 200);
    });

});

/************************************************************************/

$('.srvc-panel').slick({
        slidesToShow: 3,
        infinite: false,
        //slidesToScroll: 1,
        autoplay: true,
        centerMode: false,
        autoplaySpeed: 2000,
        arrows: false,
        speed: 500,
        variableWidth: false,
        pauseOnHover: true,
        draggable: true,
        cssEase: "linear",
        dots: true,
        responsive: [
            {
                breakpoint: 10000,
                settings: "unslick"
            },
            {
                breakpoint: 1100,
                settings: {
                    draggable: true,
                    centerMode: false,
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint:500,
                settings: {
                    centerMode: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: "0px",
                    dots: true,
                }
            }
        ],
    });

/************************************************************************/

$('.tabs-panel').slick({
    slidesToShow: 2,
    infinite: false,
    //slidesToScroll: 1,
    autoplay: true,
    centerMode: false,
    centerPadding: "0%",
    autoplaySpeed: 2000,
    arrows: false,
    speed: 500,
    variableWidth: false,
    pauseOnHover: true,
    draggable: true,
    adaptiveHeight: false,
    cssEase: "linear",
    dots: true,
    responsive: [
        {
            breakpoint: 1280,
            settings: {
                draggable: true,
                centerMode: false,
                slidesToShow: 2,
                slidesToScroll: 2,
                centerPadding: "0%",
            }
        },
        {
            breakpoint:500,
            settings: {
                draggable: true,
                centerMode: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
            }
        }
    ],
    
});

/************************************************************************/

$('.web-panel').slick({
    slidesToShow: 2,
    infinite: false,
    //slidesToScroll: 1,
    autoplay: false,
    centerMode: false,
    centerPadding: "0px",
    autoplaySpeed: 1000,
    arrows: false,
    speed: 500,
    variableWidth: false,
    pauseOnHover: true,
    draggable: true,
    cssEase: "linear",
    responsive: [
        {
            breakpoint: 1280,
            settings: {
                draggable: true,
                centerMode: false,
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint:500,
            settings: {
                draggable: true,
                centerMode: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
            }
        }
    ],
});

/************************************************************************/

$('.web2-panel').slick({
    slidesToShow: 3,
    infinite: true,
    //slidesToScroll: 1,
    autoplay: true,
    centerMode: false,
    autoplaySpeed: 2000,
    arrows: false,
    speed: 500,
    variableWidth: false,
    pauseOnHover: true,
    cssEase: "linear",
    draggable: true,
    dots: true,
    responsive: [
        {
            breakpoint: 1280,
            settings: {
                draggable: true,
                centerMode: true,
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        },
        {
            breakpoint:500,
            settings: {
                draggable: true,
                centerMode: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
            }
        }
    ],
});

/************************************************************************/

tabControl();

var resizeTimer;
$(window).on('resize', function(e) {
  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(function() {
    tabControl();
  }, 250);
});

function tabControl() {
  var tabs = $('.tabbed-content').find('.tabs');
  if(tabs.is(':visible')) {
    tabs.find('a').on('click', function(event) {
      event.preventDefault();
      var target = $(this).attr('href'),
          tabs = $(this).parents('.tabs'),
          buttons = tabs.find('a'),
          item = tabs.parents('.tabbed-content').find('.item');
      buttons.removeClass('active');
      item.removeClass('active');
      $(this).addClass('active');
      $(target).addClass('active');
    });
  } else {
    $('.item').on('click', function() {
      var container = $(this).parents('.tabbed-content'),
          currId = $(this).attr('id'),
          items = container.find('.item');
      container.find('.tabs a').removeClass('active');
      items.removeClass('active');
      $(this).addClass('active');
      container.find('.tabs a[href$="#'+ currId +'"]').addClass('active');
    });
  } 
}

/************************************************************************/

$('.team-page-panel').slick({
    slidesToShow: 4,
    infinite: false,
    //slidesToScroll: 1,
    autoplay: true,
    centerMode: true,
    autoplaySpeed: 2000,
    arrows: false,
    speed: 1000,
    variableWidth: false,
    pauseOnHover: true,
    draggable: true,
    responsive: [
        {
            breakpoint: 10000,
            settings: "unslick"
        },
        {
            breakpoint: 1100,
            settings: {
                draggable: true,
                centerMode: true,
                slidesToShow: 2,
                slidesToScroll: 2,
                centerPadding: "70px",
            }
        },
        {
            breakpoint:500,
            settings: {
                centerMode: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerPadding: "50px",
                pauseOnHover: true,
            }
        }
    ],
});

/********************************text short****************************************/

if (document.documentElement.clientWidth < 767) {
$(document).ready(function(){
	var maxLength = 500;
	$(".show-read-more").each(function(){
		var myStr = $(this).text();
		if($.trim(myStr).length > maxLength){
			var newStr = myStr.substring(0, maxLength);
			var removedStr = myStr.substring(maxLength, $.trim(myStr).length);
			$(this).empty().html(newStr);
			$(this).append('<a href="javascript:void(0);" class="read-more">Read more...</a>');
			$(this).append('<span class="more-text">' + removedStr + '</span>');
		}
	});
	$(".read-more").click(function(){
		$(this).siblings(".more-text").contents().unwrap();
		$(this).remove();
	});
});
}

/**************** pop up contact form **********************/


function closeForm() {
    $('.form-popup-bg').removeClass('is-visible');
  }
  
  $(document).ready(function($) {
    
    /* Contact Form Interactions */
    $('#btnOpenForm').on('click', function(event) {
      event.preventDefault();
  
      $('.form-popup-bg').addClass('is-visible');
    });
    
      //close popup when clicking x or off popup
    $('.form-popup-bg').on('click', function(event) {
      if ($(event.target).is('.form-popup-bg') || $(event.target).is('#btnCloseForm')) {
        event.preventDefault();
        $(this).removeClass('is-visible');
      }
    });
    
    });

/************************************************************************/
